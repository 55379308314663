import React, { useEffect, useState, useRef } from 'react'

import Karni_logo from "../../Assets/Images/karni_logo.png"
import profilePattern from "../../Assets/Images/profile_pattern.png"
import profileBlob from "../../Assets/Images/name.png"
import dobBlob from "../../Assets/Images/dob.png"
import mobileBlob from "../../Assets/Images/mobile.png"
import cityStateBlob from "../../Assets/Images/city_state.png"
import addressBlob from "../../Assets/Images/address.png"
import membershipIdBlob from "../../Assets/Images/MembershipID.png"
import nationalityBlob from "../../Assets/Images/Nationality.png"
import Styles from "./membercard.module.css"
import axios from "axios"
import { useParams } from "react-router-dom"
import { toPng, toJpeg, toBlob, toPixelData, toSvg, } from 'html-to-image';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'; import Footer from '../Footer/Footer';


import { useLocation } from "react-router-dom"
import ReactToPrint from 'react-to-print';

const MemberCard = () => {
  // getting the value/Phone passed from the download card component.
  const location = useLocation();
  const componentRef = useRef();
  const [member, setMember] = useState([])
  const [dob, setDob] = useState("")
  const [base64Image, setBase64Image] = useState("");
  const div = useRef()

  const { phone } = useParams();
  async function getMember() {
    try {
      const res = await axios.get(`https://karnisena-backend.onrender.com/api/get-member/${location.state.phone}`)
      // console.log(res)
      if (res) {
        setMember(res.data.member);
        const dob = res?.data?.member?.dob?.split("-");
        setDob(`${dob[2]}-${dob[1]}-${dob[0]}`)
        const base64 = await getBase64Image(res.data.member.profile)
        setBase64Image(base64)
      }
    } catch (err) {
      // console.log(err.message)
    }

  }




  const downloadPdf = (x, y) => {
    const divs = div.current;
    const pdf = new jsPDF();
    html2canvas(divs).then(canvas => {
      const imgData = canvas.toDataURL(member.profile);
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = canvas.height * imgWidth / canvas.width;

      // Add the image to the PDF, centered horizontally and vertically
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');

      pdf.save('My_karnisens_membership.pdf');
    });
  }


  async function getBase64Image(imgUrl) {
    // Create an image element and set its src to the image URL
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = imgUrl;

    // Wait for the image to load
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    // Create a canvas element
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    // Get the 2D context of the canvas
    const ctx = canvas.getContext("2d");

    // Draw the image onto the canvas
    ctx.drawImage(img, 0, 0);

    // Get the data URL of the canvas
    const dataURL = canvas.toDataURL("image/png");
    // console.log(dataURL)
    // Return the data URL
    return dataURL;
  }






  useEffect(() => {

    getMember();


  }, []);




  return (
    <>


      <div className={Styles.main} >

        {
          member ? (
            <>
              <div className={Styles.card_wrapper} ref={componentRef} >
                <div className={Styles.header}>
                  <div className={Styles.logo}>
                    <img src={Karni_logo} alt="" />
                  </div>
                  <div className={Styles.id}>
                    <p> <img src={membershipIdBlob} style={{ display: "inline-block", width: "20px", height: "20px" }} /> Membership Id : {String(member.memberId).toUpperCase()}   </p>
                    <p> <img src={nationalityBlob} style={{ display: "inline-block", width: "20px", height: "20px" }} /> Nationality :  {member.indianCitizen === "Yes" ? "Indian" : ""} </p>
                    <p> <img src={addressBlob} style={{ display: "inline-block", width: "20px", height: "20px", filter: "grayscale(100%)" }} /> State :  {member.state} </p>
                  </div>
                </div>



                <div className={Styles.profile_content} >
                  <div className={Styles.profile}>

                    <img className={Styles.pattern} src={profilePattern} alt="" />
                    <img className={Styles.member_image} src={base64Image} alt="" />
                  </div>
                  <div className={Styles.content}>
                    <p> <img src={profileBlob} style={{ display: "inline-block", width: "10px", marginRight: "6px", marginBottom: "3px" }} />  Name : <span style={{ display: "inline-block", color: "#BE1622" }}>{member.title} {member.name}</span> </p>
                    <p><img src={dobBlob} style={{ display: "inline-block", width: "10px", marginRight: "10px", marginBottom: "3px" }} />DOB :  <span style={{ display: "inline-block", color: "#BE1622" }}>{dob} </span> </p>
                    <p><img src={mobileBlob} style={{ display: "inline-block", width: "10px", marginRight: "8px", marginBottom: "3px" }} />Mobile No : <span style={{ display: "inline-block", color: "#BE1622" }}>{member.mobileNumber} </span>  </p>
                    <p><img src={cityStateBlob} style={{ display: "inline-block", width: "10px", marginRight: "6px", marginBottom: "3px" }} /> City : <span style={{ display: "inline", color: "#BE1622" }}>{member.district}</span>  </p>

                    <p><img src={addressBlob} style={{ display: "inline-block", width: "10px", marginRight: "10px" }} />Address : <span style={{ display: "inline", color: "#BE1622" }}>{member.homeAddress} </span>  </p>

                  </div>
                </div>

              </div>




              <div className={Styles.download_btn}>

                <ReactToPrint
                  trigger={() => <button>Download Card</button>}
                  content={() => componentRef.current}
                />
                {/* <button onClick={downloadPdf}>Download Card</button> */}
              </div>
            </>
          ) : ".... Loading"
        }
      </div>

    </>
  )
}

export default MemberCard

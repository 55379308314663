import React from 'react'
import StepperWrapper from '../../components/Stepper/Stepper'

const Home = () => {
  return (
    <div>
            <StepperWrapper/>
    </div>
  )
}

export default Home
    
import React, { useEffect, useState,useRef } from 'react'
import Styles from "../Home/downloadcard.module.css"
import { useUserAuth } from "../../Context/userAuthContext";
import Loader from '../../components/loader For Form/Loader';
import { useNavigate } from "react-router-dom"
import swal from 'sweetalert';
import axios from 'axios';
import 'react-phone-number-input/style.css'
import Banner from "../../Assets/Images/bg1.jpg"
import PhoneInput from 'react-phone-number-input'
const DownloadCard = () => {

    const { setUpRecaptcha } = useUserAuth();
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loaderState, setLoaderState] = useState(false);
    const [hasOtp, setHasOtp] = useState("");
    const [confirmObject, setConfirmObject] = useState("");
    const [enteredOtp, setEnteredOtp] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);


    async function sendOtp() {

        if (phoneNumber === "" || phoneNumber == undefined) {
            alert("number not valid");
            return;
        }
        try {
            setIsDisabled(true)
            const response = await setUpRecaptcha(phoneNumber);


            if (response) {
                setHasOtp(true);
                setConfirmObject(response);
            }


        } catch (err) {

            // console.log(err.message);
            // console.log(err);
        }
    }

    async function verifyOtp() {


        if (enteredOtp === " " || enteredOtp === null) {
            alert("enter otp please!");
        } else {
            setLoaderState(true)
            const res = await confirmObject.confirm(enteredOtp);
            if (res) {

                const member = await axios.get(`https://karnisena-backend.onrender.com/api/get-member/${phoneNumber}`)
                
       
                if (member.data.statusCode === 400) {
                    swal("Please Register First !", "You need to Register First.", "error");
                    setLoaderState(false)

                    return;
                } else {
                    navigate(`/member`,
                        { state: { phone: phoneNumber } }
                    )
                }



            }
        }
    }


    useEffect(() => {

    }, [loaderState])




    return (
        <div className={Styles.main_wrapper}>

            {
                loaderState ? <Loader /> : ""
            }

            <div className={Styles.form_wrapper}>

                {
                    hasOtp ? " " : <div className={Styles.phone_wrapper}>
                        <label htmlFor="phone">Registered Mobile*</label>
                        <div className={Styles.phone_content}>


                            <PhoneInput
                                placeholder="Enter phone number"
                                defaultCountry='IN'
                                onChange={setPhoneNumber} value={phoneNumber}
                            />


                            <button disabled={isDisabled} id="otp_btn" onClick={sendOtp}>Send OTP</button>
                        </div>
                        <div id="recaptcha-container" />
                    </div>
                }

                {
                    hasOtp ? <div className={Styles.otp_wrapper}>
                        <label className='text-success' htmlFor="phone">OTP has been sent succesfully to {phoneNumber} </label>
                        <div className={Styles.otp_content}>
                            <input type="tel" value={enteredOtp} onChange={(e) => setEnteredOtp(e.target.value)} />
                            <button onClick={verifyOtp}>Verify OTP</button>
                        </div>
                    </div> : ""
                }
            </div>



        </div>
    )
}

export default DownloadCard

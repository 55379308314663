import Styles from "./stepper.module.css";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { states } from "../../data/states";
import { Button } from "bootstrap";
import { useEffect, useState } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import axios from "axios";
// importing backdrop
import Loader from "../loader For Form/Loader";
import swal from 'sweetalert';


import { useUserAuth } from "../../Context/userAuthContext";

// import Banner from "../../Assets/Images/banner.jpg";
import Banner from "../../Assets/Images/bg4.jpg";
import { async } from "@firebase/util";
import { useNavigate } from "react-router-dom";


export default function StepperWrapper() {
  const navigate = useNavigate();
  const [shouldActiveStep1, setShouldActiveStep1] = useState(true);
  const [shouldActiveStep2, setShouldActiveStep2] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const [hasOtp, setHasOtp] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmObject, setConfirmObject] = useState("");
  const { setUpRecaptcha } = useUserAuth();
  // form fields
  const [userName, setUserName] = useState("");
  const [title, setTitle] = useState("Mr");
  const [isIndian, setIsIndian] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [state, setState] = useState("Haryana");
  const [district, setDistrict] = useState("");
  const [isConscent, setIsConscent] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [profileImageSize, setProfileImageSize] = useState("");




  function previewProfilImage(profileImg) {
    setProfileImageSize(Math.floor(profileImg.size / 1024));
    const reader = new FileReader();
    reader.readAsDataURL(profileImg);

    reader.onloadend = () => {
      setProfileImage(reader.result);
    };
  }


  async function handleSubscription() {

    if(profileImageSize>=1024){
      swal("Profile Image Size exceeded", "Image size should be less and equal to 50kb :(", "error");
        return ;
    }


    // Age validation
    if(( new Date().getFullYear()    - new Date(Date.parse(dob)).getFullYear() ) < 18 ){
    
      swal("Age below 18 are not Eligible", "", "error");
      return ;

    } 
    if (!title || !userName || !phoneNumber || !isIndian || !gender || !dob || !homeAddress || !state || !district || (!isConscent) || !profileImage) {
      swal("Please fill all fields", "you are requested to fill all fields :(", "error");
      return;
    }




    try {

      setLoaderState(true)
      // if the user is new 
      const response = await axios.post("https://karnisena-backend.onrender.com/api/new-member", { title, userName, phoneNumber, isIndian, gender, profileImage, dob, homeAddress, state, district })

      if (response) {
        // console.log(response)
        setLoaderState(false)

        swal("Thanks For Joining Karni Sena", "Now you can download your member card!", "success");
        const memberMobile = response.data.member.mobileNumber;
        navigate(`/member`, { state: { phone: memberMobile } })

      }
    } catch (err) {
      swal(`${err.message}`, "oops", "error");
      setLoaderState(false)


    }


  }



  async function sendOtp() {


    if (!userName) {
      swal("PLease Enter Name :(", " ", "error");
      return;
    }

    if (String(phoneNumber).length != 13 || phoneNumber === "") {
      swal("Mobile Number is not valid :(", " ", "error");
      return;
    }




    // if the user has already registered , we are not letting him/her to proceed further

    try {
      setLoaderState(true)

      const isOldMember = await axios.get(`https://karnisena-backend.onrender.com/api/get-member/${phoneNumber}`)
      setLoaderState(false)

      if (isOldMember.data.status !== "failure") {
        setLoaderState(false)
        swal("You Have already Registered ", "you can download your member card from Download Icard!", "error");
        return;
      }
    } catch (error) {
      setLoaderState(false)
    }

   




    // sending otp if the user is new
    try {
      // here setUpRecaptcha sets the captcha on screen and returns the signInWithPhoneNumber with some parameters and once the captcha is verified ,it sends the otp to the provided number.

      const response = await setUpRecaptcha(phoneNumber);


      setLoaderState(true)
      if (response) {
        setLoaderState(false)
        setHasOtp(true);
        setConfirmObject(response);
      }

    } catch (err) {
      // console.log(err.message);
    }
  }



  async function verifyOtp() {
    if (!userName) {
      alert("Name can not be left empty")
      return;
    }

 
    if (enteredOtp === " " || enteredOtp === null) {
      alert("enter otp please!");
    } else {
      setLoaderState(true)
      const res = await confirmObject.confirm(enteredOtp);
      if (res) {
        setLoaderState(false)
        setShouldActiveStep2(!shouldActiveStep2)
        setShouldActiveStep1(false);
      }
    }
  }




  useEffect(() => {
  }, [title, isIndian, gender, dob, state, homeAddress, district, isConscent]);

  return (
    <>
      {/* will show the loader when it takes time to send otp and also at the time of otp verification */}
      {
        loaderState ? <Loader /> : ""
      }
      <div className={Styles.banner}>
        <img src={Banner} />
      </div>





      <div className={Styles.membership_wrapper}>
        <div className={Styles.left}></div>
        <div className={Styles.right}>
          {shouldActiveStep1 ? (
            <div style={{ position: "relative" }} className={`${Styles.form} ${Styles.step1}`}>

              <h1>Join Karni Sena</h1>


              <div className={Styles.personal_details}>

                <h2>Personal Details</h2>
                <div className={Styles.name_title}>
                  <div className={Styles.title}>
                    <label htmlFor="title">Title*</label>
                    <select value={title} onChange={(e) => { setTitle(e.target.value) }} name="" id="">
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                    </select>
                  </div>

                  <div className={Styles.name}>
                    <label htmlFor="name">Name*</label>
                    <input value={userName} onChange={(e) => setUserName(e.target.value)} type="text" id="name" />
                  </div>
                </div>

                {hasOtp ? (
                  " "
                ) : (
                  <div className={Styles.mobile_no}>
                    <label htmlFor="phone">Mobile No*</label>

                    <div className={Styles.mobile_wrapper}>


                      <PhoneInput
                        style={{ width: "100%" }}
                        placeholder="Enter phone number"
                        defaultCountry='IN'
                        onChange={setPhoneNumber} value={phoneNumber}
                      />


                      <button onClick={() => sendOtp()}>Send OTP</button>
                    </div>
                  </div>
                )}

                {/* hiding the captcha as soon as otp has sent */}
                {hasOtp ? "" : <div id="recaptcha-container" />}
                {hasOtp ? (
                  <div className={Styles.mobile_no}>
                    <label htmlFor="phone" className="text-success">
                      OTP has been sent succesfully to {phoneNumber}
                    </label>
                    <div className={Styles.mobile_wrapper}>
                      <input
                        id="phone"
                        type="number"
                        placeholder="Enter Otp"
                        value={enteredOtp}
                        onChange={(e) => setEnteredOtp(e.target.value)}
                      />
                      <button onClick={verifyOtp}>Verify OTP</button>
                    </div>
                  </div>
                ) : (
                  ""
                )}




              </div>
            </div>
          ) : (
            " "
          )}

          {shouldActiveStep2 ? (
            <div className={Styles.step2}>
              {
                loaderState ? <Loader /> : ""
              }
              <div className={Styles.citizen}>
                <p>Are you an indian Citizen ? </p>
                <input value="Yes" type="radio" name="citizen" id="yes" onChange={(e) => setIsIndian(e.target.value)} />
                <label htmlFor="yes">Yes</label>
                <input onChange={(e) => setIsIndian(e.target.value)} id="no" type="radio" name="citizen" value="No" />
                <label htmlFor="no">No</label>
              </div>

              <div className={Styles.gender}>
                <p>Gender :- </p>
                <input value="male" type="radio" name="gender" id="male" onChange={(e) => setGender(e.target.value)} />
                <label htmlFor="male">Male</label>
                <input id="female" type="radio" name="gender" value="female" onChange={(e) => setGender(e.target.value)} />
                <label htmlFor="female">Female</label>
              </div>

              <div className={Styles.profile_pic}>
                <Form.Group>
                  <label htmlFor="profile">Choose Profile image*</label>
                  <br />
                  <Form.Control
                    id="profile"
                    onChange={(e) => previewProfilImage(e.target.files[0])}
                    type="file"
                  />
                  {
                    profileImage ? <span style={{ fontSize: "12px",  color : profileImageSize>=1024 ? "red" : "green"      }}>

                     {
                      profileImageSize >=1024 ? "File Size exceeded" : "Good : File Size is in limit "
                     } 
                    </span > : (

                     <span style={{ fontSize: "12px" }}>*File size can not exceed 1mb</span>
                    )
                  }

                </Form.Group>

                {profileImage ? (
                  <img src={profileImage} alt="" />
                ) : (
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/1177/1177568.png"
                    alt=""
                  />

                )}
              </div>

              <div className={Styles.dob}>
                <label for="dob">Date of Birth*</label>
                <input value={dob} onChange={(e) =>{ setDob(e.target.value); }} type="date" id="dob" name="dob" />
              </div>

              <div className={Styles.contact_details}>
                <h2>Contact Details</h2>
                <div className={Styles.contact_form_wrapper}>
                  <div className={Styles.home_address}>
                    <label htmlFor="home_add">Home Address*</label>
                    <input value={homeAddress} onChange={(e) => setHomeAddress(e.target.value)} type="text" />
                  </div>

                  <div className={Styles.state_and_district}>
                    <div className={Styles.state_wrapper}>
                      <label htmlFor="states">States*</label>
                      <div className={Styles.states}>
                        <select
                          name="states"
                          id="states"
                          defaultValue={"Haryana"}
                          onChange={(e) => setState(e.target.value)}
                        >
                          {states.map((state, index) => {
                            return (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className={Styles.district}>
                      <label htmlFor="states">District*</label>
                      <input value={district} onChange={(e) => setDistrict(e.target.value)} type="text" />
                    </div>
                  </div>

                  <div className={Styles.conscent}>
                    <input checked={isConscent} onChange={(e) => setIsConscent(e.target.checked)} type="checkbox" name="" id="" />
                    <p>
                      I certify that above provided information is correct and
                      there is no mistake. I know that all further communication
                      will be done on above provided details.
                    </p>
                  </div>

                  <button onClick={handleSubscription}>Join</button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>


    </>
  );
}

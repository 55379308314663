import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBw9B0NcESLUJETTKrXdzdnfTb5K5d0EHM",
  authDomain: "phone-auth-7e2f6.firebaseapp.com",
  projectId: "phone-auth-7e2f6",
  storageBucket: "phone-auth-7e2f6.appspot.com",
  messagingSenderId: "711065785789",
  appId: "1:711065785789:web:52d44bc6d41a7d90ba2ed4",
  measurementId: "G-B9LQDN4PP4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;

import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../loader For Form/Loader';




export default function Members() {
    const navigate = useNavigate();
    const [members, setMembers] = useState([]);
    const [searchedMembers, setSearchedMembers] = useState([]);




    function searchWinner(searchTerm) {


        if (searchTerm !== "") {
            let temp = members;
            // Here we are traversing the array of objects using filter method and only returning those objects that has the text which is searched for.
            let filteredResult = temp.filter((member) => {
                return Object.values(member).join(" ").toLowerCase().includes(searchTerm.toLowerCase());

            })

            // After getting the particular results that includes  the searched text , we are storing it in a another state and rendering it oon the screen.
            setSearchedMembers(filteredResult);
        } else {
            // In case if search box is empty or no search is made, then simply store all the members/ results coming from the api and just show it in the screen.
            setSearchedMembers(members);
        }
    }



    async function getMembers() {
        try {
            const token = JSON.parse(window.sessionStorage.getItem("adminAuth")).token;
            const members = await axios("https://karnisena-backend.onrender.com/admin/members", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            setMembers(members.data.member)
            setSearchedMembers(members.data.member);

        } catch (error) {
            navigate("/admin")
        }
    }

    useEffect(() => {
        getMembers()
    }, [])



    
    return (
        <div style={{ padding: "10px 40px 40px 40px" }}>
            <h3 style={{ textAlign: "center", marginBottom: "30px", textTransform: "uppercase" }}>Karni Sena  Members</h3>

            <div className='search_box' style={{ marginBottom: "30px" }}>
                <input onChange={(e) => searchWinner(e.target.value)} style={{ width: "25%", outline: "none", height: "37px", borderRadius: "5px", border: "1px solid gray", paddingLeft: "10px" }} type="search" placeholder='Search Member Here' />
            </div>




            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>S.No</TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Profile</TableCell>
                            <TableCell align="center">Phone</TableCell>
                            <TableCell align="center">State</TableCell>
                            <TableCell align="center">DOB</TableCell>
                            <TableCell align="center">Member Id</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {

                            searchedMembers.length ? searchedMembers.map((member, index) => {
                                return <TableRow
                                    key={index + 4}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="center">{member.title} {member.name}</TableCell>
                                    <TableCell align="center">

                                        <img style={{ width: "50px", height: "50px", borderRadius: "50%" }} src={member.profile} />
                                    </TableCell>
                                    <TableCell align="center">{member.mobileNumber}</TableCell>
                                    <TableCell align="center">{member.state}</TableCell>
                                    <TableCell align="center">{member.dob}</TableCell>
                                    <TableCell align="center">{member.memberId}</TableCell>
                                </TableRow>
                            }) : <Loader/>


                        }

                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
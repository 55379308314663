import { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup,
    RecaptchaVerifier,
    signInWithPhoneNumber
} from "firebase/auth";

import { auth } from "../config/firebase";




const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {


    const [user, setUser] = useState({});

    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }
    function signUp(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }
    function logOut() {
        return signOut(auth);
    }
    function googleSignIn() {
        const googleAuthProvider = new GoogleAuthProvider();
        return signInWithPopup(auth, googleAuthProvider);
    }

    function setUpRecaptcha(number) {
     
        const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container',{}, auth);
        recaptchaVerifier.render();
        return signInWithPhoneNumber(auth, number, recaptchaVerifier)
    }


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
            setUser(currentuser);
        });

        return () => {
            unsubscribe();
        };
    }, []);



    return (
        <userAuthContext.Provider
            value={{ user, logIn, signUp, logOut, googleSignIn, setUpRecaptcha }}
        >
            {children}
        </userAuthContext.Provider>
    );
}







export function useUserAuth() {
    return useContext(userAuthContext);
}

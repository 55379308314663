import React from 'react'

const Footer = () => {

 

    return (
        <div >
            <h2  style={{textAlign:"center",fontSize:"1.1rem",padding:"25px"}}>

                Copyright 2023 Karnisena - All Rights Reserved</h2>
        </div>
    )
}

export default Footer

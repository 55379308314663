import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
const Loader = () => {
  return (
    <div style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', left: "0", top: "0",flexDirection:"column",zIndex:"999" }}>
      <Spinner style={{ borderBottom: "1px solid", borderTop: "1px solid" }} animation="border" variant="light" />
      <p style={{color:"white",fontFamily:"monospace"}}>Please Wait !</p>
    </div>
  )
}

export default Loader


import karniSenaLogo from "../../Assets/Images/karni_logo.png"
import Styles from "./navbar.module.css"
import { Link, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useEffect, useState } from "react";



// This function is reponsi
function Navigation() {
    const [shouldShowLogout, setShouldShowLogout] = useState(false);

    const navigate = useNavigate();

    async function logoutHandler() {
        setShouldShowLogout(false);
        window.sessionStorage.removeItem("adminAuth");
        navigate("/admin")


    }


    useEffect(() => {
        try {
            const token = JSON.parse(window.sessionStorage.getItem("adminAuth")).token;
            if(token){
            
                setShouldShowLogout(true)
            }
        } catch (error) {
    
            // console.log(error.message)
        }
    
    })
    
    



    return (
        <>

            <Navbar bg="light" expand={"md"} className="">
                <Container fluid className="gap-4">
                    <Navbar.Brand href="https://karnisena.in/">
                        <img src={karniSenaLogo} width={150} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-sm`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                                <img src={karniSenaLogo} width={150} />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body >
                            <Nav className="justify-content-start gap-4  flex-grow-1 pe-3 align-items-center">
                                <a className={Styles.link} href="https://karnisena.in/">Home</a>
                                <Link className={Styles.link} to="/download-card">Download Card</Link>

                            </Nav>

                            <Nav className="" style={{ padding: "0 20px" }}>

                                {
                                    shouldShowLogout ? <Button className="btn-warning text-white" onClick={logoutHandler}>Logout</Button> : ""
                                }

                            </Nav>
                            {/* <Form className="d-flex">
                                    <Form.Control
                                        type="search"
                                        placeholder="Search"
                                        className="me-2"
                                        aria-label="Search"
                                    />
                                    <Button variant="outline-success">Search</Button>
                                </Form> */}
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

        </>
    );
}

export default Navigation;
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import swal from 'sweetalert'
import Styles from "./login.module.css"
import { useNavigate } from "react-router-dom"
const Login = () => {
  const navigation = useNavigate();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")


  async function loginHandler(event) {

    event.preventDefault();
    if (!email || !password) {
      swal("Email or Password is missing", "Both fields are required", "error");
      return;
    } else {

      try {
        const response = await axios.post("https://karnisena-backend.onrender.com/admin/login", {
          email, password
        })

        if (response.data.accessToken) {

          window.sessionStorage.setItem("adminAuth", JSON.stringify({

            token: response.data.accessToken,
            user: response.data.user.name

          }))


          navigation("/admin/members")

        } else {
         setErrorMessage(response.data.message)
        }


      } catch (error) {
        console.log(error)
      }




    }







  }


  useEffect(() => {
    try {
      const token = JSON.parse(window.sessionStorage.getItem("adminAuth")).token;
      if (token) {
        navigation("/admin/members")

      }
    } catch (error) {

      // console.log(error.message)
    }

  }, [])





  return (
    <div>
      <div className={Styles.main_wrapper}>

        <h2>Welcome Back, Admin</h2>

        <form className={Styles.form} action="">


          <input type="text" value={email} onChange={(event) => { setEmail(event.target.value); setErrorMessage("") }} placeholder='Enter Email Here' />
          <input type="text" value={password} onChange={(event) => { setPassword(event.target.value); setErrorMessage("") }} placeholder='Enter Password Here' />
          {
            errorMessage ? <span style={{ color: "red" }}>{errorMessage}</span> : ""
          }
          <button onClick={loginHandler}>Login</button>

        </form>
      </div>
    </div>
  )
}

export default Login

import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./Pages/Home/Home"
import Navbar from "./components/Navbar/Navbar"
import MemberCard from "./components/memberCard/MemberCard"
import { UserAuthContextProvider } from "./Context/userAuthContext";
import DownloadCard from "./Pages/Home/DownloadCard";
import Footer from "./components/Footer/Footer";
import Login from "./components/Admin/Login"
import Members from "./components/All Members/Members";
// pages import 


function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <UserAuthContextProvider>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/member" exact element={< MemberCard />} />
          
              <Route path="/admin" exact element={< Login />} />
              <Route path="/admin/members" exact element={<Members />} />

    
            <Route path="/download-card" exact element={< DownloadCard />} />
          </Routes>
        </UserAuthContextProvider>
        <Footer />
      </BrowserRouter>
    </>
  );
}


export default App;
